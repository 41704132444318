@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quantico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    font-family: 'Quantico','Open Sans', "sans-serif";
    text-align: center;
    height: 100vh;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

p{
   font-family: "Quantico", "sans-serif";
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    color:white; 
    text-decoration:none; 
    cursor:pointer;  
}

.only-mobile{
    display: none !important;
}

.only-desktop{
    display: inline-block !important;
} 


.heading-container{
    position: absolute;
    padding: 20px;
    border-radius: 5px;
    background: rgba(48, 48, 48, 0.205);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3.5px );
    top: 30vh;
    right: 20vw;
    z-index: 100;
}

@keyframes fade-out {
    from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
}

@keyframes fade-in {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}

.heading-name{
    font-family: 'Exo 2', sans-serif;
    color: white;
    margin: 15px;
}

.heading-text{
    color: white;
}

.view-btn{
    color: white;
    height: 50px;
    width: 50%;
    margin-top: 10px;
    border-radius: 5px;
    background: rgba(48, 48, 48, 0.205);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3.5px );
    font-size: 1em;
    /* font-weight: 600; */
    font-family: "Quantico", "sans-serif";

}

.backBtn{
    position: absolute;
    bottom: 70px;
    right: 50%;
    transform: translateX(50%);
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: rgba(48, 48, 48, 0.418);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.26);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3.5px );
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 105;
    display: none;
}

.backBtn:hover{
    background: rgba(48, 48, 48, 0.521);
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}

.iconBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.loading-bar-cover{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: black;
    z-index: 105;
}

.loading-bar-container{
    position: absolute;
    height: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.lottie-container{
    position: relative;
    width: 150px;
    height: 150px;
    margin: auto;
}

.loading-bar{
    position: relative;
    width: 0;
    height: 3px;
    background-color: white;
    z-index: 101;
    margin-bottom: 15px;
}

.loading-heading{
    position: relative;
    z-index: 101;
    color: white;
}

.earth-lottie{
    position: relative;
    z-index: 101;
    margin: auto;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.loading-finish-container{
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.or{
    color: white;
    margin: 10px 0 6px 0;
}

.quick-projects-view{
    color: white;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
}

.enter-btn{
    position: relative;
    display: inline-block;
    color: white;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    padding: 15px 22px;
    border-radius: 1px;
    cursor: pointer;
}

.toggle-screen-size-btn{
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    color:white;
    background: transparent;
    border-style: none;
    cursor: pointer;
    z-index: 101;
}

.small-screen-icon{
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 100;
    display: none;
}

.full-screen-icon{
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 100;
}

.mute-btn{
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 35px;
    height: 35px;
    color:white;
    background: transparent;
    border-style: none;
    cursor: pointer;
    z-index: 101;
}

.sound-on-icon{
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 100;
    display: none;

}

.sound-off-icon{
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 35px;
    height: 35px;
    color: white;
    z-index: 100;

}

.picer-project-window{
    position: absolute;
    border-radius: 5px;
    width: 500px;
    margin-left: 550px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    display: none;
    animation: scale-up 0.7s ease;
    animation-fill-mode: both;
    z-index: 104;
}

.sapochat-project-window{
    position: absolute;
    border-radius: 5px;
    margin: auto;
    width: 500px;
    margin-left: 550px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    display: none;
    animation: scale-up 0.7s ease;
    animation-fill-mode: both;
    z-index: 104;
}

.imagic-project-window{
    position: absolute;
    border-radius: 5px;
    margin-left: 550px;
    width: 500px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    display: none;
    animation: scale-up 0.7s ease;
    animation-fill-mode: both;
    z-index: 104;
}

.news-project-window{
    position: absolute;
    border-radius: 5px;
    width: 500px;
    margin-left: 550px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    display: none;
    animation: scale-up 0.7s ease;
    animation-fill-mode: both;
    z-index: 104;
}

.flex-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.flappy-project-window{
    position: absolute;
    border-radius: 5px;
    margin-left: 550px;
    width: 500px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    display: none;
    animation: scale-up 0.7s ease;
    animation-fill-mode: both;
    z-index: 104;
}

@keyframes scale-up {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scale-down {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

/* .app-img-container{
    display: flex;
    justify-content: center;
    align-items: top;
    overflow: hidden;
    width: 100%;
    height: 260px;
    margin: auto;
} */

.picer-photo{
    width: 100%;
    height: auto;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.imagic-video{
    width: 100%;
    height: 230px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.app-description-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 30px 30px 30px;
}

.picer-heading{
    color: white;
    font-family: 'Quantico', sans-serif;
    margin-top: 15px;
}

.app-description--text{
    text-align: center;
    color: white;
}

.picer-link{
    color: rgb(111, 111, 255);
    font-family: 'Quantico';
    margin-top: 5px;
}

.picer-link a:visited {
    color: rgb(111, 111, 255);
}

.nav-bar{
 position: fixed;
 margin: auto;
 top: 15px;
 right: 0;
 left: 0;
 z-index: 103;
}

.journey{
    margin: 7px;
    margin-top: 0;
    position: relative;
    color: white;
    display: inline-block;
    cursor: pointer;
}

.my-work{
    margin: 7px;
    margin-top: 0;
    cursor: pointer;
    position: relative;
    color: white;
    display: inline-block;
}

.contact-us{
    margin: 7px;
    margin-top: 0;
    cursor: pointer;
    position: relative;
    color: white;
    display: inline-block;
}

.credits{
    margin: 7px;
    margin-top: 0;
    cursor: pointer;
    position: relative;
    color: white;
    display: inline-block;
}

.navbar-window{
    position: fixed;
    top: -450px;
    right: 0;
    left: 0;
    margin: auto;
    width: 300px;
    /* height: 165px; */
    /* display: inline-block; */
    /* padding: 20px; */
    border-radius: 5px;
    background: rgba(26, 25, 25, 0.705);
    box-shadow: 0 8px 32px 0 rgba(24, 23, 23, 0.26);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 3.5px );
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 104;
}

.journey-text-earth{
    font-weight: 1000;
}

.journey-text-spaceship{
    
}

.journey-text-mars{
    
}

.close-contact{
    position: absolute;
    top: 7px;
    right: 7px;
    color: white;
    cursor: pointer;
}

.close-work-btn{
    height: 50px;
    width: 50px;
    z-index: 107;
    background-color: transparent;
    border-style: none;
}

.contact-details{
    position: relative;
    /* display: inline-block; */
    margin: 30px;
    color: white;
}

.credits-text{
    position: relative;
    margin: 30px;
    color: white;
}

.instructions-window{
    position: fixed;
    top: 50px;
    right: 0;
    left: 0;
    margin: auto;
    width: 300px;
    height: 100px;
    color: white;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    z-index: 101;
}

#window-mars-animation{
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0;
}

#window-spaceship-animation{
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0;
}

#window-arrival-animation{
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    opacity: 0;
}


.instructions-content{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    margin: 10px;
}

.lottie-click{
    position: absolute;
    top: -25px;
    right: 0;
    left: 0;
    margin: auto;
}

.lottie-scroll{
    position: absolute;
    top: -20px;
    right: 0;
    left: 0;
    margin: auto;
}

.lottie-scroll-safary{
    display: none;
    position: absolute;
    top: 32px;
    right: 0;
    left: 0;
    margin: auto;
}

.about-us-container{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;

}

.timer{
    font-family: 'Orbitron', sans-serif;
    margin-top: 8px;
}

.about-us-text-container{
    position: absolute;
    width: 500px;
    height: 200px;
    margin: auto;
    left: 50%;
    top: 50vh;
    transform: translate(-50%,-50%);
}

.about-us-text{
    position: relative;
    color: white;
}

.text-1{
    opacity: 0;
    margin-bottom: 500px;
}

.text-2{
    opacity: 0;
    margin-bottom: 500px;
}

.text-3{
    opacity: 0;
    margin-bottom: 500px;
}

.skip-travel{
    cursor: pointer;
    color: white;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
}

.white-transision{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: white;
    display: none;
    z-index: 106;
}

.static-work-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 100%;
    bottom: 100%;
    right: 0;
    left: 0;
    background-color: black;
    background-image: linear-gradient(rgb(0, 0, 0), rgba(51, 51, 51, 0.596));
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    z-index: 107;
    overflow-y: scroll;
    display: flex;
}

.projects{
    text-align: center;
    max-width: 1600px;
    width: 100%;
    margin: auto;
    position: relative;
    --swiper-theme-color: rgb(226, 226, 226);
}

.static-projects-heading{
    color: white;
    background: -webkit-linear-gradient(white, #38495a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.project-card{
    display: inline-block;
    position: relative;
    background-color: rgb(43, 43, 43);
    border-radius: 5px;
    max-width: 600px;
    min-width: 300px;
    width: 85%;
}

.img-card{
    background-color: rgb(0, 0, 0);
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.img-card:hover {
    opacity: 0.4;
    transition: all 0.4s ease-in-out;
}

.imagic-video-static{
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    border-radius: 5px 5px 0 0;    
}

.card-heading{
    color: white;
    font-size: 20px;
    margin: 0;
}

.app-description-static{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 60px;
}

.links{
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    border-width: 1px 0 0 0;
    border-color: white;
    margin: 10px 0 20px 0;
}

.project-link{
    color: rgb(111, 111, 255);
    text-decoration: unset;
}

.project-link:hover{
    font-size: 18px;
    transition: all 0.4s ease-in-out;
}

.github-link{
    text-decoration: unset;
    color: black;
}

.github-link:hover{
    font-size: 18px;
    transition: all 0.4s ease-in-out;
}

.app-description--text{
    color: white;
    font-size: 16px;
    margin: 0;
}

/* ---------Swiper--------- */
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-wrapper{
  padding: 15px 0 40px 0;
  align-items: center;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 600px;
  min-width: 300px;
  display: block;
  width: 100%;
}



/* Mobile css */
@media (max-width: 800px) {
    .heading-container{
        right: 0;
        left: 0;
        top: 80px;
        width: 70vw;
        margin: auto;
        font-size: 10px;
        padding-top: 5px;
        background: rgba(94, 94, 94, 0.164);
    }

    .view-btn{
        font-size: 16px;
        height: 40px;
        width: 45%;
        margin-top: 0px;
    }

    .backBtn{
        width: 70px;
        height: 70px;
        bottom: 20px;
    }

    .iPhoneBackBtn{
        width: 70px;
        height: 70px;
        bottom: 10px;
    }

    .iconBtn {
        width: 30px;
        height: 30px;
    }
    
    .picer-heading{
        margin-top: 10px;
    }

    .picer-link{
        font-size: 14px;
    }

    .app-description--text{
        font-size: 14px;
    }

    .picer-project-window{
        margin-left: 0;
        width: 85vw;
    }

    /* .iphone-picer-project-window{
        height: 32em;
        bottom: 50px;
    } */

    .sapochat-project-window{
        margin-left: 0;
        width: 85vw;
    }

    /* .iphone-sapochat-project-window{
        height: 30em;
    } */

    .imagic-project-window{
        margin-left: 0;
        width: 85vw;
    }

    .imagic-picer-project-window{
        height: 32em;
        bottom: 50px;
    }

    .news-project-window{
        margin-left: 0;
        width: 85vw;
    }

    .flappy-project-window{
        margin-left: 0;
        width: 85vw;
    }

    .about-us-text-container{
        width: 80vw;
    }

    .swiper{
        padding-bottom: 10px;
    }

    .app-description-container{
        margin: 0 25px 25px 25px;
        gap: 10px;
    }
    
    .app-description-static{
        padding: 20px 30px;
    }

}

/* -----------------Low res desktop----------------- */
@media (min-width: 800px) and (max-width: 1500px){
    .heading-container{
        font-size: 8px;
        padding: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .view-btn{
        font-size: 12px;
        height: 36px;
        margin: 0;
    }
    .backBtn{
        width: 100px;
        height: 100px;
        right: 50%;
        transform: translateX(50%);
        bottom: 15px;
    }
    .iconBtn {
        width: 35px;
        height: 35px;
    }
    .picer-link{
        font-size: 13px;
    }
    .app-description-container{
        margin: 0 25px 25px 25px;
        gap: 10px;
    }
    .app-description--text{
        font-size: 13px;
    }
    .picer-project-window{
        width: 370px;
        margin-left: 430px;
    }
    .picer-heading{
        margin-top: 10px;
    }
    .sapochat-project-window{
        width: 370px;
        margin-left: 430px;
    }
    .imagic-project-window{
        width: 370px;
        margin-left: 430px;
    }
    .news-project-window{
        width: 370px;
        margin-left: 430px;
    }
    .flappy-project-window{
        width: 370px;
        margin-left: 430px;
    }
    .bi-box-arrow-up-right{
        size: 8px;
    }
}
